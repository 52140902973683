.product-wrap {
    margin-bottom: 1.5rem;
}
.product {
    padding: 0;
    border: 0;
    position: relative;
    font-size: 1.4rem;
    color: #999;
    height: 100%;
    transition: .3s;

    .product-media {
        position: relative;
        margin-bottom: 0;
        transition: box-shadow .3s;
        & > a {
            display: block;
        }
        img:last-child {
            position: absolute;
            opacity: 0;
            left: 0;
            right: 0;
            top: 0;
            transition: all .5s;
            width: 100%;
        }
        img:first-child {
            position: relative;
            opacity: 1;
            transition: all .5s;
            width: 100%;
        }
    }
    .product-label-group {
        position: absolute;
        top: 0;
        padding: 0 5px;
    }
    .product-action-vertical {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        transition: opacity .3s, visibility .3s;
        opacity: 0;
        visibility: hidden;
    }
    .product-action {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        background-color: transparent;
        z-index: 10;
        transition: opacity .3s, visibility .3s;
        opacity: 0;
        padding: .5rem;
    }
    .product-details {
        position: relative;
        padding: 15px;
    }
    .btn-product-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        margin-bottom: 0.5rem;
        border: 1px solid #e1e1e1;
        border-radius: 50%;
        background-color: white;
        color: #999;
        font-size: 16px;
        font-weight: 700;
        transition: border-color .3s, color .3s, background-color .3s;
        &:hover {
           color: #fff;
            background-color: var(--bs-btn-bg);
        }
    }
    .btn-product {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        text-transform: uppercase;
        transition: opacity .3s;
        font-size: 14px;
    }
    .product-cat {
        margin-bottom: 0.5rem;
        font-size: 13px;
        font-weight: 400;
        line-height: 1;
        text-transform: uppercase;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        text-decoration: none;
        a {
            text-decoration: none;
        }
    }
    .product-title {
        text-align: center;
        text-decoration: none;
        font-size: 18px;
        font-weight: 700;
        a {
            text-decoration: none;
        }
    }
    .product-short-description {
        line-height: 1.2;
    }
    .product-price {
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 0.3rem;
        font-size: 16px;
        line-height: 1.5;
        color: var(--bs-body-color);
        justify-content: center;
        ins.new-price {
            color: var(--bs-primary);
            text-decoration: none;
            font-weight: 600;
        }
        .vat {
            padding: 0 5px;
            opacity: .75;
            font-size: 12px;
        }
    }
    .prices + .box_variants_list {
        margin-top: 1rem;
    }
    .box_variants_list + .box_variants_list {
        margin-top: .25rem;
    }
    .box_variants_list {
        display: table;
        width: 100%;
        clear: both;
        .variant-title {
            font-size: 12px;
            line-height: 26px;
            width: 100px;
            text-align: left;
            display: table-cell;
            vertical-align: middle;
        }
        .list-variants {
            list-style: none;
            display: flex;
            margin: 0;
            padding: 0;
            flex-wrap: wrap;
            li a {
                display: block;
                padding: 0 6px;
                height: 24px;
                border: 1px solid black;
                line-height: 24px;
                margin: 0 1px 3px;
                font-size: 12px;
                text-decoration: none;
                color: $black;
            }
            li.checkbox-color a {
                border-width: 0;
                border-color: #fff;
                border-radius: 20%;
                width: 24px;
                font-size: 0;
            }
        }
        .checkbox-size, .checkbox-color {
            position: relative;
        }
    }

    &:hover, &:focus {
        .product-action, .product-action-vertical {
            visibility: visible;
            opacity: 1;
        }
        .product-media {
            img:first-child {
                opacity: 0;
            }
            img:last-child {
                opacity: 1;
            }
        }
    }
}

.list-view {
    .product {
        display: flex;
        border: 1px solid $border-color;
        .product-media {
            flex: 0 0 300px;
            max-width: 300px;
            margin-right: 15px;
        }
        .product-details {
            position: relative;
            flex-grow: 1;
        }
        .product-cat {
            text-align: left;
        }
        .product-title {
            text-align: left;
            font-size: 21px;
        }
        .product-short-description {
            font-size: 1rem;
            margin-bottom: 1rem;
        }
        .product-price {
            text-align: left;
            justify-content: flex-start;
            ins.new-price {
                font-size: 20px;
            }
        }
        .product-action.product-action-1 {
            opacity: 0;
            display: none;
        }
        .product-action.product-action-2 {
            opacity: 1;
            padding: 0;
            position: relative;
            margin-top: 2rem;
            .btn-product {
                flex: 0;
                width: auto;
                --bs-btn-padding-x: 1.75rem;
            }
        }
    }
}
.grid-view {
    .product {
        .product-action.product-action-2 {
            opacity: 0;
            display: none;
        }
        .product-short-description {
            display: none;
        }
    }
}

.single-product {
    padding: 3rem 0;

    .gallery-wrap {
        position: relative;
        margin-bottom: 1.5rem;
        .product-image {
            position: relative;
            .btn {
                position: absolute;
                top: 10px;
                right: 10px;
                z-index: 3;
            }
            img {
                aspect-ratio: 1 / 1;
                object-fit: cover;
                cursor: crosshair;
            }
        }
        .zoomImg {
            content-visibility: auto;
        }
        .product-thumb {
            img {
                aspect-ratio: 1 / 1;
                object-fit: cover;
            }
        }
    }
    .product-details {
        .alert {
            line-height: 1;
            font-weight: 600;
            font-size: 1rem;
            & > * {
                margin: 0;
            }
        }
    }
    .product-label-group {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 2;
    }
    #image-carousel {
        .owl-item {
            padding: 1px;
        }
    }
    #thumb-carousel {
        .owl-item {
            padding: 1px;
        }
        .owl-item.current img {
            border-color: var(--product-gallery-current);
            outline: 1px solid var(--product-gallery-current);
        }
    }
    .product-brand-image {

    }
    .product-brand-badge {
        @extend .badge;
        background-color: var(--product-brand-badge);
        text-decoration: none;
        font-size: .875rem;
        margin-bottom: 0.5rem;
    }
    .product-name {
        font-size: 2rem;
    }
    .product-short-description {
        margin-top: 0.5rem;
    }
    .product-price {
        .new-price {
            font-size: 1.75rem;
            font-weight: 700;
            text-decoration: none;
            color: var(--bs-primary);
        }
        .vat {
            padding: 0 5px;
            opacity: .75;
            font-size: 12px;
        }
    }
    .product-sku, .product-cats, .product-stock {
        margin-bottom: 0.5rem;
    }
    .product-tags {
        b {
            margin-right: 0.25rem;
        }
    }
    .product-tags .single-tag {
        text-decoration: none;
        font-weight: 400;
        display: inline-block;
        text-align: center;
        margin-bottom: 0.35rem;
        padding: 0.5rem 0.8rem;
        width: auto;
        height: auto;
        line-height: 1;
        border: 1px solid $border-color;
        border-radius: 2px;
        color: var(--bs-body-color);
        -webkit-transition: border-color 0.35s, color 0.35s;
        transition: border-color 0.35s, color 0.35s;
        &:hover, &:focus {
            color: var(--product-tag-color-hover);
            border-color: var(--product-tag-color-hover);
        }
    }
    .product-variations {
        border-top: 1px solid $border-color;
        padding: 1rem 0;
    }
    .product-meta {
        border-top: 1px solid $border-color;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
    .product-form {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        border-top: 1px solid $border-color;
    }
    .product-form + .product-footer, .product-meta + .product-footer {
        border-top: 1px solid $border-color;
        padding-top: 1rem;
    }
    .product-footer {
        display: flex;
        flex-wrap: wrap;
        & > .btn {
            margin: 3px;
            flex-grow: 1;
        }
        span {
            flex-grow: 1;
            margin: 3px;
            & > .btn {
                width: 100%;
            }
        }
    }
    .checkbox-size {
        position: relative;
        input[type=checkbox], input[type=radio] {
            position: absolute;
            left: 0;
            z-index: -1;
            width: 1rem;
            height: 1.25rem;
            opacity: 0;
        }
        .btn {
            min-width: 3.5em;
            height: 3.5em;
            line-height: 3.4em;
            padding: 0 3px;
            font-weight: 500;
            font-size: 12px;
            border: 1px solid black;
            background-color: white;
        }
        input:checked ~ .btn {
            color: white;
            background-color: black;
        }
    }
    .checkbox-color {
        position: relative;
        margin: 2px 0;
        input[type=checkbox], input[type=radio] {
            position: absolute;
            left: 0;
            z-index: -1;
            width: 1rem;
            height: 1.25rem;
            opacity: 0;
        }
        .btn {
            border-width: 3px;
            border-color: #fff;
            border-radius: 15%;
            width: 2.5em;
            height: 2.5em;
            padding: 0;
            text-indent: -9999px;
        }
        input:checked ~ .btn {
            border-color: white;
            outline: 2px solid black;
            box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
        }
    }
    .product-info {
        padding: 2rem 0 0;
    }
    .nav-product-info {
        .nav-link[aria-expanded="true"] {
            text-decoration: none;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
            display: block;
            padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
            font-size: var(--bs-nav-link-font-size);
            font-weight: var(--bs-nav-link-font-weight);
            color: var(--bs-nav-pills-link-active-color);
            background-color: var(--bs-nav-pills-link-active-bg);
            pointer-events: none;
        }
    }
    .nav-tabs {
        border-color: $border-color;
    }
    #accordion-tabs {
        .collapsing {
            height: auto;
            transition: none;
        }
    }
}

.product-label {
    padding: 3px 5px;
    border-radius: 4px;
    color: white;
    font-size: 12px;
    line-height: 1.3;
    &.label-new {
        background-color: var(--bs-btn-bg);
    }
    &.product-label-custom-color-1 {
        background-color: var(--product-label-custom-color-1);
    }
    &.product-label-custom-color-2 {
        background-color: var(--product-label-custom-color-2);
    }
}

#promo {
    span {
        @extend .badge;
        background-color: $danger;
        color: #fff;
    }
}
#nav-view {
    .nav-link {
        font-size: 0.875rem;
        border-radius: 3px;
        border: 1px solid transparent;
        padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
        --bs-btn-padding-y: 0.25rem;
        --bs-btn-padding-x: 0.5rem;
        color: var(--bs-btn-bg);
        &:hover, &:focus {
            color: var(--bs-btn-hover-bg);
        }
    }
    .nav-link.active {
        background-color: var(--bs-btn-bg);
        color: var(--bs-btn-color);
        border-color: var(--bs-btn-border-color);
        &:hover, &:focus {
            background-color: var(--bs-btn-hover-bg);
            border-color: var(--bs-btn-hover-border-color);
        }
    }

}

@include media-breakpoint-down(lg) {
    .product .box_variants_list .variant-title {
        display: block;
    }
}
@include media-breakpoint-down(md) {
    .single-product {
        padding: 1rem 0 3rem 0;
        .product-name {
            font-size: 1.5rem;
        }
        .product-meta {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
        .product-sku, .product-cats, .product-stock {
            margin-bottom: 0.25rem;
        }
        .product-description-inner {
            font-size: .95rem;
        }
    }
    .grid-view {
        .product-wrap {
            margin-bottom: 1rem;
        }
        .product {
            .product-cat {
                font-size: 12px;
                margin-bottom: 0.25rem;
            }
            .product-title {
                font-size: 15px;
            }
            .product-price {
                margin-bottom: 0;
                line-height: 1.2;
            }
            .product-details {
                padding: 15px 0 0;
            }
        }
    }
    .list-view {
        .product {
            align-items: center;
            .product-media {
                flex: 0 0 150px;
                max-width: 150px;
                margin-right: 10px;
            }
            .product-title {
                font-size: 18px;
            }
            .product-short-description {
                font-size: 14px;
                line-height: 1.5;
                margin-bottom: 0.5rem;
            }
            .prices + .box_variants_list {
                margin-top: 0.5rem;
            }
            .product-action.product-action-2 {
                margin-top: 1rem;
            }
        }
    }
    #accordion-tabs {
        .card-header {
            font-weight: 700;
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            border: 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:after {
                font-family: "bootstrap-icons";
                content: "\F284";
                font-weight: normal;
            }
            &[aria-expanded="true"]:after {
                content: "\F282";
            }
        }
        .card + .card {
            margin-top: 5px;
        }
    }
    #nav-view {
        .nav-link span {
            display: none;
        }
    }
}
@include media-breakpoint-up(md) {
    .single-product {
        .product-info {
            .card {
                border: 0;
                background-color: transparent;
            }
            .card-body {
                padding: 0;
            }
            .nav-tabs {
                width: 100%;
                margin-bottom: 1rem;
            }
            .nav-tabs .nav-link {
                font-size: 18px;
                color: var(--bs-body-color);
                opacity: .75;
                border-bottom: 2px solid transparent;
            }
            .nav-tabs .nav-link[aria-expanded="true"] {
                font-weight: 600;
                opacity: 1;
                border-bottom-color: var(--product-tabs-active-color);
            }
        }
    }
}
